<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="900"
    scrollable
    persistent
    @keydown.esc="goToSuppliersPage"
  >
    <SupplierForm
      :supplier="newSupplier"
      :errors="supplierValidationErrors"
      :disabled="$store.getters.loading['post:api/suppliers']"
      @clear:errors="clearSupplierValidationErrors"
      @cancel="goToSuppliersPage"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import SupplierForm from '@/components/SupplierForm';

export default {
  name: 'CreateSupplier',

  components: { SupplierForm },

  computed: mapState('suppliers', [
    'newSupplier',
    'supplierValidationErrors',
    'suppliersFilterParams',
  ]),

  created() {
    this.RESET_SUPPLIER_VALIDATION_ERRORS();
  },

  methods: {
    ...mapActions('suppliers', ['storeSupplier', 'clearSupplierValidationErrors']),
    ...mapMutations('suppliers', ['RESET_SUPPLIER_VALIDATION_ERRORS']),

    onSave(supplier) {
      this.storeSupplier(supplier).then(() => this.goToSuppliersPage());
    },

    goToSuppliersPage() {
      this.$router.push({ name: 'suppliers', query: this.suppliersFilterParams }).catch(() => {});
    },
  },
};
</script>

<style scoped></style>
